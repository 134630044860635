import { createGetKcContext } from "keycloakify/login";

export type KcContextExtension =
  // NOTE: A 'keycloakify' field must be added
  // in the package.json to generate theses extra pages
  // https://docs.keycloakify.dev/build-options#keycloakify.extrapages
  | { pageId: "my-extra-page-1.ftl" }
  | { pageId: "my-extra-page-2.ftl"; someCustomValue: string }
  // NOTE: register.ftl is deprecated in favor of register-user-profile.ftl
  // but let's say we use it anyway and have this plugin enabled: https://github.com/micedre/keycloak-mail-whitelisting
  // keycloak-mail-whitelisting define the non standard ftl global authorizedMailDomains, we declare it here.
  | {
      pageId: "login-recovery-authn-code-config.ftl";
      recoveryAuthnCodesConfigBean: {
        generatedRecoveryAuthnCodesList: string[];
        generatedRecoveryAuthnCodesAsString: string;
        generatedAt: string;
      };
      properties: {
        kcRecoveryCodesWarning: string;
        kcRecoveryCodesList: string[];
        kcRecoveryCodesActions: string;
        kcCheckClass: string;
        kcRecoveryCodesConfirmation: string;
        kcCheckInputClass: string;
        kcCheckLabelClass: string;
        kcFormClass: string;
        kcButtonClass: string;
        kcButtonPrimaryClass: string;
        kcButtonLargeClass: string;
        kcButtonDefaultClass: string;
        kcButtonBlockClass: string;
      };
    }
  | {
      pageId: "login-recovery-authn-code-input.ftl";
      recoveryAuthnCodesInputBean: {
        codeNumber: string;
      };
      properties: {
        kcFormClass: string;
        kcFormGroupClass: string;
        kcLabelWrapperClass: string;
        kcLabelClass: string;
        kcInputWrapperClass: string;
        kcInputClass: string;
        kcInputErrorMessageClass: string;
        kcFormOptionsClass: string;
        kcFormOptionsWrapperClass: string;
        kcFormButtonsClass: string;
        kcButtonClass: string;
        kcButtonPrimaryClass: string;
        kcButtonBlockClass: string;
        kcButtonLargeClass: string;
      };
      messagesPerField: {
        code: {
          required: string;
          invalid: string;
        };
      };
    }
  | { pageId: "register.ftl"; authorizedMailDomains: string[] };

//NOTE: In most of the cases you do not need to overload the KcContext, you can
// just call createGetKcContext(...) without type arguments.
// You want to overload the KcContext only if:
// - You have custom plugins that add some values to the context (like https://github.com/micedre/keycloak-mail-whitelisting that adds authorizedMailDomains)
// - You want to add support for extra pages that are not yey featured by default, see: https://docs.keycloakify.dev/contributing#adding-support-for-a-new-page
export const { getKcContext } = createGetKcContext<KcContextExtension>({
  mockData: [
    {
      pageId: "login.ftl",
      locale: {
        //When we test the login page we do it in french
        currentLanguageTag: "de",
      },
      //Uncomment the following line for hiding the Alert message
      // message: undefined,
      //Uncomment the following line for showing an Error message
      message: { type: "error", summary: "This is an error" },
    },
    {
      pageId: "my-extra-page-2.ftl",
      someCustomValue: "foo bar baz",
    },
    {
      //NOTE: You will either use register.ftl (legacy) or register-user-profile.ftl, not both
      pageId: "register-user-profile.ftl",
      locale: {
        currentLanguageTag: "en",
      },
      recaptchaRequired: true,
      recaptchaSiteKey: "6LcIvAQcAAAAAGYj3ATVq-EOZXntqBVIjh3U3aV6",
      profile: {
        attributes: [
          {
            validators: {
              pattern: {
                pattern: "^[a-zA-Z0-9]+$",
                "ignore.empty.value": true,
                // eslint-disable-next-line no-template-curly-in-string
                "error-message": "${alphanumericalCharsOnly}",
              },
            },
            //NOTE: To override the default mock value
            value: undefined,
            name: "username",
          },
          {
            validators: {
              options: {
                options: [
                  "male",
                  "female",
                  "non-binary",
                  "transgender",
                  "intersex",
                  "non_communicated",
                ],
              },
            },
            // eslint-disable-next-line no-template-curly-in-string
            displayName: "${gender}",
            annotations: {},
            required: true,
            groupAnnotations: {},
            readOnly: false,
            name: "gender",
          },
        ],
      },
    },
    {
      pageId: "register.ftl",
      authorizedMailDomains: [
        "example.com",
        "another-example.com",
        "*.yet-another-example.com",
        "*.example.com",
        "hello-world.com",
      ],
      // Simulate we got an error with the email field
      messagesPerField: {
        printIfExists: <T>(fieldName: string, className: T) => {
          return fieldName === "email" ? className : undefined;
        },
        existsError: (fieldName: string) => fieldName === "email",
        get: (fieldName: string) => `Fake error for ${fieldName}`,
        exists: (fieldName: string) => fieldName === "email",
      },
      recaptchaRequired: true,
      recaptchaSiteKey: "6LcIvAQcAAAAAGYj3ATVq-EOZXntqBVIjh3U3aV6",
      realm: {
        name: "master",
        registrationEmailAsUsername: true,
      },
    },
    {
      pageId: "login-recovery-authn-code-config.ftl",
      isAppInitiatedAction: true,
      recoveryAuthnCodesConfigBean: {
        generatedRecoveryAuthnCodesList: [
          "123456789",
          "987654321",
          "123456789",
          "987654321",
          "123456789",
          "987654321",
          "123456789",
          "987654321",
          "123456789",
          "987654321",
        ],
        generatedRecoveryAuthnCodesAsString: "123456789,987654321",
        generatedAt: "1.686.820.019.340",
      },
      properties: {
        kcRecoveryCodesWarning: "kcRecoveryCodesWarning",
        kcRecoveryCodesList: ["kcRecoveryCodesList"],
        kcRecoveryCodesActions: "kc-recovery-codes-actions",
        kcCheckClass: "kcCheckClass",
        kcRecoveryCodesConfirmation: "kc-recovery-codes-confirmation",
        kcCheckInputClass: "kcCheckInputClass",
        kcCheckLabelClass: "pf-c-check__label",
        kcFormClass: "kcFormClass",
        kcButtonClass: "kcButtonClass",
        kcButtonPrimaryClass: "kcButtonPrimaryClass",
        kcButtonLargeClass: "kcButtonLargeClass",
        kcButtonDefaultClass: "kcButtonDefaultClass",
        kcButtonBlockClass: "kcButtonBlockClass",
      },
    },
    {
      pageId: "login-recovery-authn-code-input.ftl",
      recoveryAuthnCodesInputBean: {
        codeNumber: "123456789",
      },
      properties: {
        kcFormClass: "kcFormClass",
        kcFormGroupClass: "kcFormGroupClass",
        kcLabelWrapperClass: "kcLabelWrapperClass",
        kcLabelClass: "kcLabelClass",
        kcInputWrapperClass: "kcInputWrapperClass",
        kcInputClass: "kcInputClass",
        kcInputErrorMessageClass: "kcInputErrorMessageClass",
        kcFormOptionsClass: "kcFormOptionsClass",
        kcFormOptionsWrapperClass: "kcFormOptionsWrapperClass",
        kcFormButtonsClass: "kcFormButtonsClass",
        kcButtonClass: "kcButtonClass",
        kcButtonPrimaryClass: "kcButtonPrimaryClass",
        kcButtonBlockClass: "kcButtonBlockClass",
        kcButtonLargeClass: "kcButtonLargeClass",
      },
    },
    // {
    //   pageId: "webauthn-authenticate.ftl",
    //   authenticators: {
    //     authenticators: [
    //       {
    //         credentialId: "credentialId",
    //         transports: {
    //           iconClass: "iconClass",
    //           displayNameProperties: {
    //             at: () => undefined,
    //             length: 1,
    //             pop: () => undefined,
    //             push: () => 1,
    //             concat: () => new Array(undefined),
    //             join: () => "displayName",
    //             reverse: () => new Array(undefined),
    //             shift: () => undefined,
    //             slice: () => new Array(undefined),
    //             sort: () => new Array(undefined),
    //             splice: () => new Array(undefined),
    //             unshift: () => 1,
    //             indexOf: () => 1,
    //             lastIndexOf: () => 1,
    //             every: () => true,
    //             some: () => true,
    //             forEach: () => undefined,
    //             map: () => new Array(undefined),
    //             filter: () => new Array(undefined),
    //             reduce: () => undefined,
    //             reduceRight: () => undefined,
    //             find: () => undefined,
    //             findIndex: () => 1,
    //             fill: () => new Array(undefined),
    //             copyWithin: () => new Array(undefined),
    //             includes: () => true,
    //             flatMap: () => undefined,
    //             flat: () => undefined,
    //             [Symbol.iterator]: () => undefined,
    //             [Symbol.unscopables]: () => undefined,
    //             entries: () => {
    //               return {
    //                 [Symbol.iterator]: () => {
    //                   return {
    //                     next: () => {
    //                       return {
    //                         done: true,
    //                         value: undefined,
    //                       };
    //                     },
    //                   };
    //                 },
    //                 next: () => {
    //                   return {
    //                     done: true,
    //                     value: undefined,
    //                   };
    //                 },
    //               };
    //             },
    //             keys: () => undefined,
    //             values: () => undefined,
    //           },
    //         },
    //       },
    //     ],
    //   },
    //   challenge: "challenge",
    //   shouldDisplayAuthenticators: true,
    //   userVerification: "required",
    //   rpId: "rpId",
    //   stateChecker: "stateChecker",
    // },
    {
      pageId: "login-config-totp.ftl",
      // mode: "app",
      totp: {
        // enabled: true,
        otpCredentials: [
          {
            id: "1",
            userLabel: "",
            // createdDate: "1.687.971.288.176",
          },
        ],
        supportedApplications: [
          "totpAppMicrosoftAuthenticatorName",
          "totpAppGoogleName",
        ],
        totpSecretEncoded: "INCW QYTS ONBG S2KU IRWX A6BU GBXG 4R2B",
        qrUrl: "#?qr",
        policy: {
          type: "totp",
          // getAlgorithmKey: () => "INCW QYTS ONBG S2KU IRWX A6BU GBXG 4R2B",
          digits: 6,
          period: 30,
          // initialCounter: 0,
          // algorithm: "SHA1",
        },
        totpSecretQrCode:
          "iVBORw0KGgoAAAANSUhEUgAAAPYAAAD2AQAAAADNaUdlAAACoUlEQVR4Xu2YMY6DMBBFJ0pByRG4SbgYEpG4WLgJR6BMgXb2vzERCcU2W3iKuGCJn1eyx3/G35j/2TY793y2Lz/3fLYvP/d8NvhqZhf3RT/vnf/YYP5oN3XamIOP7vN6XRqhzm7t3Rpfhy5ACj5YA/fZJw1q3Z8Xcbtk4j65pr51/tAi4pGLr9YpqtY9ie/z4lsiXvbfJdJNKJTASk76qMfJH224FvH2OOVXRR5tHRcnvxXfPbWjZeCkjgpO11B1/Ke9d2S66WcSbhYbrl4rpSceWkkOrqqj+VMVVcQbgszUl6cl4WR1rzepcqQ0Em7iy88MnPnP6JOtt16V8tkrf9BsDq5aIyRpsv9RKR/kzwxKwKVFvatq92WkuotmX/WpMhdSVEOQqj9m10XxvfqRP5X5rsoIaCgBNOXhTP1mJutlkTra/5lKfnnpszbXqUxoY+rWh5NQTuXhMoQ91prUwUQoyaVZPXJw/INREDn1jEhr/htKTcKF1BGDcIX6y/xDuBl4Ca1cIf5hL0IMPvK/NjfyB5M4UnrMit1Bril4yZUlCrYZ/h8RRCXPwDEMaovQRH4P3J9YiefgxX/F1WRg1hPl3KSEI75VeVh/9ClVUn/u5A9O9rW+yjzOEv3ZRcobU3/P77qcq7vJMFhLVZS1lp2QHcOEZeCkzkOhfd3aw0kk4nqoFkqk7H8Z1BPzWxKu8yUShiLuIVJMtpx2/Gt9Hg3DKnUWExH+P750ZeArWaNcIbQMnxiuk/q4f1bmYwiybLhKDyc18z/0W5lzF/a4Hw/oc6/fcRym4UpyArrEm5aDf/A9vhk4HXyf1kk4Ee5psbf8rsvZfwSpraeSGyY7Pjfs86/NI3+w/m25f2IS375v1eZ/tS8/93y2Lz/3fLZ/818mSF983H8r1wAAAABJRU5ErkJggg==",
        totpSecret: "INCW QYTS ONBG S2KU IRWX A6BU GBXG 4R2B",
        manualUrl: "#?manual",
      },
      client: {
        clientId: "account",
        // baseUrl: "http://localhost:8080/auth",
      },
      realm: {
        name: "master",
      },
    },
  ],
});

export const { kcContext } = getKcContext({
  // Uncomment to test the login page for development.
  // mockPageId: "register-user-profile.ftl",
  // mockPageId: "register.ftl",
  // mockPageId: "login-otp.ftl",
  // mockPageId: "login.ftl",
  // mockPageId: "login-update-password.ftl",
  // mockPageId: "error.ftl",
  // mockPageId: "login-reset-password.ftl",
  // mockPageId: "login-config-totp.ftl",
  // mockPageId: "select-authenticator.ftl",
  // mockPageId: "login-update-profile.ftl",
  // mockPageId: "webauthn-authenticate.ftl",
  // mockPageId: "login-recovery-authn-code-config.ftl",
  // mockPageId: "login-recovery-authn-code-input.ftl",
});

export type KcContext = NonNullable<
  ReturnType<typeof getKcContext>["kcContext"]
>;
